@font-face {
  font-family: 'PT Sans';
  src: url('pt-sans.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Space Mono';
  src: url('space-mono.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
html,
body {
  padding: 0px;
  margin: 0px;
}
body {
  height: 100vh;
  font-family: 'PT Sans', sans-serif;
  color: #111;
  background-color: #dddddd;
  display: flex;
  flex-direction: column;
}
body.beats {
  background-color: #333;
}
.spacer {
  flex-grow: 1;
  align-self: center;
  width: 0;
}
#date {
  align-self: center;
  text-align: center;
  font-size: 7vw;
}
body.beats #date {
  color: #9d0;
  font-family: 'Space Mono', monospace;
  font-size: 20vw;
}
#settings-wrapper {
  align-self: stretch;
}
#settings {
  display: flex;
  background-color: #fff;
  border-top: 1px solid #111;
  padding: 4px;
  font-size: 10pt;
  margin: 0px;
  flex-direction: row;
  flex-wrap: wrap;
  list-style-type: none;
  align-items: baseline;
}
#settings li {
  margin: 4px;
}
#settings li:last-of-type {
  margin-left: auto;
  align-self: center;
}
#gh-logo {
  width: 1.5em;
  height: 1.5em;
  fill: #111;
}
select,
option {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}
